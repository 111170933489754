import React from 'react';
import styled from 'styled-components';

const Profile = props => {
  const userName = localStorage.getItem('userName');
  const userEmail = localStorage.getItem('userEmail');
  const userId = localStorage.getItem('userId');

  return (
    <Main>
      <hr className="mt-2 mb-4" />
      <h4 className="text-center">My profile</h4>
      <p>Name: {userName}</p>
      <p>Email: {userEmail}</p>
      <p>User Id: {userId}</p>
    </Main>
  );
};

export default Profile;

const Main = styled.div`
  margin: auto;
  max-width: 700px;
  padding: 20px;
`;
