import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import logo2 from '../images/EthicsNetLogoLong.png';

const Header = (props) => {
  const { UserStore } = props;
  useEffect(() => {
    UserStore.getCurrentUser();
  }, [UserStore]);

  return (
    <header className="navbar justify-content-between">
      <StyledLink to="/" className="navbar-brand">
        {/* <Logo src={logo} alt="ethics-net-logo" /> */}
        <Logo src={logo2} alt="ethics-net-logo" />
      </StyledLink>
      <StyledHeader>
        <StyledLink to="/">Dataset</StyledLink>
        <StyledLinkA href="https://www.ethicsnet.org/blog/" target="_blank">
          Blog
        </StyledLinkA>
        <StyledLinkA href="https://www.ethicsnet.org/about/" target="_blank">
          About
        </StyledLinkA>
        <StyledLinkA href="https://www.ethicsnet.org/contact/" target="_blank">
          contact
        </StyledLinkA>
        {UserStore.user.admin && <StyledLink to="/admin">Admin</StyledLink>}
        {localStorage.getItem('isLogged') || UserStore.isLogged ? (
          <StyledLink to="/profile">My Profile</StyledLink>
        ) : (
          <StyledLink to="/signup">JOIN ETHICSNET</StyledLink>
        )}
        {UserStore.isLogged ? (
          <StyledButton onClick={() => UserStore.signUserOut(props)}>
            logout
          </StyledButton>
        ) : (
          <StyledLink to="/login">log in</StyledLink>
        )}
      </StyledHeader>
    </header>
  );
};

export default withRouter(inject('UserStore')(observer(Header)));

const StyledHeader = styled.div`
  @media (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Logo = styled.img`
  margin-left: 35px;
  margin-bottom: 20px;
  width: 200px;
`;

const StyledLinkA = styled.a`
  color: #999;
  font-size: 13px;
  margin-left: 30px;
  text-transform: uppercase;

  :hover {
    color: #222;
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledButton = styled.button`
  color: #999;
  background-color: transparent;
  border: none;
  font-size: 13px;
  margin-left: 30px;
  text-transform: uppercase;
  outline: none;
  :hover {
    color: #222;
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)`
  color: #999;
  font-size: 13px;
  margin-left: 30px;
  text-transform: uppercase;
  :hover {
    cursor: pointer;
    text-decoration: none;
    color: #222;
  }
`;
