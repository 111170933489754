import React, { Component } from 'react';
// import styled, { css } from 'styled-components';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import { withRouter } from 'react-router-dom';

@withRouter
@inject('notificationStore', 'dilemmaStore', 'UserStore')
@observer
class CreateComment extends Component {

  componentDidMount() {
    if (!this.props.UserStore.isLogged) {
      this.props.history.push('/login');
    }
  }

  @observable description = '';

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value;
  };

  @action handleSubmit = e => {
    e.preventDefault();
    const database = firebase.database();

    const { dilemma_id } = this.props.match.params;

    let newCommentKey = database
      .ref()
      .child('dilemmas' + dilemma_id + '/comments')
      .push().key;
    let updates = {};

    const author_id = localStorage.getItem('userId');
    const author_name = localStorage.getItem('userName');

    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    }

    const newComment = {
      author_name: author_name ? author_name : 'anonymous',
      author_id: author_id ? author_id : '0',
      comment_id: newCommentKey,
      time_created: formatDate(Date()),
      description: this.description,
    };

    updates[
      '/dilemmas/' + dilemma_id + '/comments/' + newCommentKey
    ] = newComment;
    return database.ref().update(updates, error => {
      if (error) {
        this.props.notificationStore.createNotification('error', error)();
      } else {
        this.props.notificationStore.createNotification(
          'success',
          'comment created'
        )();
        this.props.dilemmaStore.dilemmaValue = 3;
      }
    });
  };

  handleKeyPress = e => {
    if (e.charCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    return (
      <div>
        <h5 className="text-center mb-4">create new comment</h5>
        <form onSubmit={this.handleSubmit} onKeyPress={this.handleKeyPress}>
          <div className="form-group">
            <label htmlFor="description">comment</label>
          </div>
          <textarea
            type="text"
            className="form-control"
            rows="3"
            onChange={this.handleInputChange}
            value={this.description}
            name="description"
            placeholder="insert your comment here"
            required
          />
          <button className="btn btn-success mt-3 mb-3">Create comment</button>
        </form>
      </div>
    );
  }
}

export default CreateComment;
