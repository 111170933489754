import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Layout from './components/Layout';

function Main() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default Main;

/*
TODO:
FOR BETTER PERFORMANCE ON THE REQUESTS:

  maybe this:

  dilemmas {
    ... dilemma info
  }

  cases {
    dilemmaid {
      ... cases related to that dilemma
    }
  }

  comments {
    dilemmaid {
      ... comments related to that dilemma
    }
  }

  instead of:
  this would cause bad performance cause when it only need to show,
  on the homepage, only basic info about the dilemmas it would
  download all the cases and comments as well

  dilemmas {
    ... dilemma info
    cases {
      ... all cases
    }
    comments {
      ... all comments
    }
  }


*/
