import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Main } from './AuthStyles';

@inject('UserStore')
@observer
class Signup extends Component {
  @observable createdAt = '';
  @observable email = '';
  @observable lastLogin = '';
  @observable name = '';
  @observable password = '';
  @observable profileImgUrl = '';

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value;
  };

  @action handleSubmit = e => {
    e.preventDefault();

    const { signupUser } = this.props.UserStore;
    signupUser(
      this.email,
      this.password,
      this.name,
      this.profileImgUrl,
      this.props
    );
  };

  render() {
    return (
      <Main>
        <h3 className="mb-4 text-center">Welcome to EthicsNet!</h3>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              required
              value={this.email}
              onChange={this.handleInputChange}
              name="email"
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input
              required
              minLength="6"
              value={this.password}
              onChange={this.handleInputChange}
              name="password"
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              required
              minLength="3"
              value={this.name}
              onChange={this.handleInputChange}
              name="name"
              type="text"
              className="form-control"
              id="name"
              placeholder="Your name"
            />
          </div>

          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="remember-me"
            />
            <label className="form-check-label" htmlFor="remember-me">
              remember me
            </label>
          </div>
          <button type="submit" className="btn btn-primary w-100 mt-3">
            Create account
          </button>
        </form>
      </Main>
    );
  }
}

export default Signup;
