import React from 'react';
import styled from 'styled-components';

const Loader = () => {
  return (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Loader;

export const FullLoader = styled.div`
  align-items: center;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 75vh;
  position: absolute;
  width: 100%;
  z-index: 5;
`;
