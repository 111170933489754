import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react';
import UserStore from './stores/UserStore';
import dilemmaStore from './stores/dilemmaStore';
import notificationStore from './stores/notificationStore';
import ErrorHanlder from './components/ErrorHandler';

import * as firebase from 'firebase/app';
import firebaseConfig from './firebaseConfig';

import './index.scss';

firebase.initializeApp(firebaseConfig);

// Mobx stores should be added here
const Root = (
  <Provider
    UserStore={UserStore}
    dilemmaStore={dilemmaStore}
    notificationStore={notificationStore}
  >
    <ErrorHanlder>
      <Main />
    </ErrorHanlder>
  </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
