import React from 'react';

function Confirmation() {
  return (
    <div className="mt-5 text-center">
      <h5 className="text-primary">Please check your email</h5>
      <h5 className="text-secondary">
        we have sent you a verification email, follow the instructions to reset
        your password
      </h5>
    </div>
  );
}

export default Confirmation;
