import React from 'react';
import styled from 'styled-components';
import { NotificationContainer } from 'react-notifications';
import { Route, Switch } from 'react-router-dom';

import Login from '../auth/Login';
import Signup from '../auth/Signup';
import Admin from '../auth/Admin';
import ResetPassword from '../auth/ResetPassword';
import CreateDilemma from '../dilemma/CreateDilemma';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Dilemmas from '../dilemma/Dilemmas';
import Dilemma from '../dilemma/Dilemma';
import NotFound from '../partials/NotFound';
import Profile from './Profile';
import Confirmation from './Confirmation';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

function Layout() {
  return (
    <>
      <Wrapper className="container">
        <Header />
        <NotificationContainer />
        <Switch>
          <Route exact path="/" component={Dilemmas} />
          <PublicRoute
            restricted={true}
            exact
            path="/signup"
            component={Signup}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/login"
            component={Login}
          />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/admin" component={Admin} />
          <PublicRoute
            restricted={true}
            exact
            path="/reset_password"
            component={ResetPassword}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/email_sent"
            component={Confirmation}
          />
          <PrivateRoute exact path="/dilemma/new" component={CreateDilemma} />
          <Route exact path="/dilemma/:dilemma_id" component={Dilemma} />
          <Route component={NotFound} />
        </Switch>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Layout;

const Wrapper = styled.section`
  min-height: calc(100vh - 35px);
`;
