import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Link } from 'react-router-dom';

import { Main } from './AuthStyles';

@inject('UserStore')
@observer
class Login extends Component {
  @observable email = '';
  @observable password = '';

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value;
  };

  @action handleSubmit = e => {
    e.preventDefault();
    const { logUserIn } = this.props.UserStore;
    logUserIn(this.email, this.password, this.props);
  };

  render() {
    return (
      <Main>
        <h3 className="mb-4 text-center">Please login</h3>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              value={this.email}
              onChange={this.handleInputChange}
              name="email"
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              value={this.password}
              onChange={this.handleInputChange}
              name="password"
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
            />
          </div>
          <small>
            <Link to="/reset_password">Forgot your password?</Link>
          </small>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              remember me
            </label>
          </div>

          <button type="submit" className="btn btn-primary w-100 mt-3">
            Login
          </button>
        </form>
      </Main>
    );
  }
}

export default Login;
