import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app';
import 'firebase/database';
import { observable } from 'mobx';

import Loader from '../components/Loader';

@inject('UserStore')
@observer
class Admin extends Component {
  @observable loading = true;
  @observable users = [];
  @observable isAdmin = false;

  componentDidMount() {
    firebase
      .database()
      .ref('/users')
      .once('value')
      .then(snapshot => {
        this.users = Object.values(snapshot.val());
        this.loading = false;
        this.isAdmin = this.props.UserStore.user.admin;
      })
      .catch(error =>
        console.log('there was an error fetching the data', error)
      );
  }

  makeAdmin = uid => {
    if (
      window.confirm(
        'are you sure you want to GIVE admin privileges to this user?'
      )
    ) {
      let updates = {};
      updates['/users/' + uid + '/admin'] = true;
      return firebase
        .database()
        .ref()
        .update(updates, error => {
          if (error) {
            console.log('there was an error -> ', error);
          } else {
            window.location.reload();
          }
        });
    }
  };

  makeDeputy = uid => {
    if (
      window.confirm(
        'are you sure you want to GIVE deputy privileges to this user?'
      )
    ) {
      let updates = {};
      updates['/users/' + uid + '/deputy'] = true;
      return firebase
        .database()
        .ref()
        .update(updates, error => {
          if (error) {
            console.log('there was an error -> ', error);
          } else {
            window.location.reload();
          }
        });
    }
  };

  removeAdmin = uid => {
    if (
      window.confirm(
        'are you sure you want to REMOVE admin provileges to this user?'
      )
    ) {
      let updates = {};
      updates['/users/' + uid + '/admin'] = null;
      return firebase
        .database()
        .ref()
        .update(updates, error => {
          if (error) {
            console.log('there was an error -> ', error);
          } else {
            window.location.reload();
          }
        });
    }
  };

  removeDeputy = uid => {
    if (
      window.confirm(
        'are you sure you want to REMOVE deputy privileges to this user?'
      )
    ) {
      let updates = {};
      updates['/users/' + uid + '/deputy'] = null;
      return firebase
        .database()
        .ref()
        .update(updates, error => {
          if (error) {
            console.log('there was an error -> ', error);
          } else {
            window.location.reload();
          }
        });
    }
  };

  render() {
    if (this.loading) {
      return (
        <Main className="align-items-center">
          <Loader />
        </Main>
      );
    }

    if (!this.isAdmin) {
      return (
        <Main className="row">
          <p>ops, this is not what you're looking for...</p>
        </Main>
      );
    }

    const users = this.users.map(user => {
      return (
        <User key={user.uid}>
          <hr />
          <div>
            <h6>{user.name}</h6>
            <p>{user.email}</p>
            {user.admin ? <p>admin: true</p> : <p>admin: false</p>}
          </div>
          <div>
            <button
              onClick={() => this.makeAdmin(user.uid)}
              className="btn btn-info"
              disabled={user.admin}
            >
              Make Admin
            </button>
            <button
              onClick={() => this.removeAdmin(user.uid)}
              className="btn btn-danger ml-2"
              disabled={!user.admin}
            >
              Remove Admin
            </button>
          </div>

          <div className="mt-3">
            {user.deputy ? <p>deputy: true</p> : <p>deputy: false</p>}

            <div>
              <button
                onClick={() => this.makeDeputy(user.uid)}
                className="btn btn-secondary"
                disabled={user.deputy}
              >
                Make Deputy
              </button>
              <button
                onClick={() => this.removeDeputy(user.uid)}
                className="btn btn-danger ml-2"
                disabled={!user.deputy}
              >
                Remove Deputy
              </button>
            </div>
          </div>
        </User>
      );
    });

    return (
      <Main className="row">
        <h5 className="text-primary text-center m-1">
          You're an admin <br /> you can remove or give admin access to other
          users
        </h5>
        <p className="text-info text-center m-1">
          you have admin privileges, you can delete/edit any dilemma or comment
        </p>
        {users}
      </Main>
    );
  }
}

export default Admin;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const User = styled.div`
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 10px;
  :hover {
    cursor: pointer;
  }
`;
