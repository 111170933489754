import React, { Component } from 'react';
import styled from 'styled-components';

class ErrorHandler extends Component {
  state = { errorOccurred: false };

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
    console.log(error, info);
  }

  render() {
    return this.state.errorOccurred ? (
      <Main>
        <h4 className="mt-4">Ethits Net</h4>
        <h3>Ops... something went wrong!</h3>
      </Main>
    ) : (
      this.props.children
    );
  }
}

export default ErrorHandler;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;
