import { observable, action } from 'mobx';

class dilemmaStore {
  @observable dilemmaValue = 1;
  @observable caseId = '';
  @observable commentId = '';

  @observable selectedImg = '';

  @action getImgUrl = num => {
    switch (num) {
      case 1:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FAutomotive-Autonomous-Vehicles.png?alt=media&token=addce92b-e699-4694-b6e3-3e593f231a90';
      case 2:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FMedical-Pharma.png?alt=media&token=d0eba348-f4ba-440a-ab09-fafab747ecc0';
      case 3:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FCaring-Nursing.png?alt=media&token=f7214a1d-7ea8-4e61-9e1f-b072245eb0cf';
      case 4:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FCustomer-Relations.png?alt=media&token=8ccab5b3-16e9-4aee-8262-7657b38fec44';
      case 5:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FSocial-Interactions.png?alt=media&token=1d447945-7af1-4b1b-8fb9-304157bb3c57';
      case 6:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FBusiness-Trade-Contracts.png?alt=media&token=49193cb0-f47a-45f7-b45a-6918b58c9d66';
      case 7:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FFinancial-Insurance.png?alt=media&token=1ed2c648-f63a-4d8e-bb74-e66a354726d7';
      case 8:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FRobotics-Embedded-Systems.png?alt=media&token=1078a81f-ffd1-4d54-b6be-3e479c5736fc';
      case 9:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FApplied-Ethics.png?alt=media&token=5d6546a3-3b68-4550-bd06-2e4ba9b608c1';
      case 10:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FLegal-Liberties.png?alt=media&token=8a76befc-6195-4427-8668-666b4b589ad4';
      case 11:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FInternational-Affairs.png?alt=media&token=002fa21d-c1b3-4e53-b390-15405a09817e';
      case 12:
        return 'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2FMilitary-Policing.png?alt=media&token=4337c446-843c-4f25-a8c0-deef63754b4d';
      default:
        return '';
    }
  };

  @action setDilemmaValue = (value, type, id) => {
    this.dilemmaValue = value;
    if (type === 'case') {
      this.caseId = id;
    } else if (type === 'comment') {
      this.commentId = id;
    }
  };
}

export default new dilemmaStore();
