import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

function Cases({ cases, dilemmaStore }) {
  if (!cases) {
    return (
      <Main className="d-flex align-items-center justify-content-center flex-column">
        <div className="text-center mt-3">
          there are not cases for this dilemma yet...
        </div>
        <button
          onClick={() => dilemmaStore.setDilemmaValue(5)}
          className="btn btn-success mt-3 w-50"
        >
          add case
        </button>
      </Main>
    );
  }

  const arr = Object.entries(cases).map(el => {
    const [key, value] = el;
    return {
      key,
      value,
    };
  });

  const casesEl = arr.map(el => (
    <CasesStyled
      key={el.key}
      onClick={() => dilemmaStore.setDilemmaValue(6, 'case', el.key)}
    >
      <p>{el.value.title}</p>
      <StyledButton>read more</StyledButton>
      <p>{el.value.description}</p>
      <div>{`Correct action: ${el.value.correctAction}`}</div>
    </CasesStyled>
  ));

  return (
    <Main className="row">
      <div className="col-md-3">
        <StyledP>CASES</StyledP>
        <button
          onClick={() => dilemmaStore.setDilemmaValue(5)}
          className="btn btn-success"
        >
          add case
        </button>
      </div>
      <div className="col-md-9">
        {cases ? casesEl : <p>there was an error...</p>}
      </div>
    </Main>
  );
}

export default withRouter(inject('dilemmaStore')(observer(Cases)));

const Main = styled.div`
  font-size: 14px;
`;

const StyledP = styled.p`
  padding: 10px;
`;

const CasesStyled = styled.div`
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 15px;

  :hover {
    box-shadow: 1px 1px 5px #999;
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  color: #999;
  background-color: transparent;
  border: none;
  font-size: 12px;
  outline: none;
  margin-bottom: 15px;
  padding: 0;
  text-transform: uppercase;

  :hover {
    color: #222;
    cursor: pointer;
    text-decoration: none;
  }
`;
