import React from 'react';
import styled from 'styled-components';

const FooterEl = styled.footer`
  background-color: #f5f5f5;
  border-top: 1px solid #dcd6d6;
  bottom: 0;
  font-size: 10px;
  height: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
`;

const Footer = () => {
  return <FooterEl>EthicsNet - {new Date().getFullYear()}</FooterEl>;
};

export default Footer;
