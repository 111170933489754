import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import { withRouter } from 'react-router-dom';

import Loader from '../components/Loader';

@withRouter
@inject('UserStore', 'notificationStore', 'dilemmaStore')
@observer
class Comment extends Component {
  @observable commentInfo = {};
  @observable loading = true;

  componentDidMount() {
    const { dilemma_id } = this.props.match.params;
    const comment_id = this.props.dilemmaStore.commentId;

    firebase
      .database()
      .ref('/dilemmas/' + dilemma_id + '/comments/' + comment_id)
      .once('value')
      .then(snapshot => {
        this.commentInfo = snapshot.val();
        this.loading = false;
      });
  }

  handleCommentDelete = () => {
    const { UserStore, notificationStore, dilemmaStore } = this.props;
    const author_id = this.commentInfo.author_id;

    if (
      UserStore.user.uid === author_id ||
      UserStore.user.admin ||
      UserStore.user.deputy
    ) {
      const { dilemma_id } = this.props.match.params;
      const comment_id = this.props.dilemmaStore.commentId;

      const confirm = window.confirm(
        'are you sure you want to delete this comment?'
      );
      if (confirm) {
        let updates = {};
        updates['/dilemmas/' + dilemma_id + '/comments/' + comment_id] = {};
        return firebase
          .database()
          .ref()
          .update(updates, error => {
            if (error) {
              notificationStore.createNotification('error', error)();
            } else {
              notificationStore.createNotification(
                'success',
                'comment deleted'
              )();
              dilemmaStore.dilemmaValue = 3;
            }
          });
      }
    }
  };

  render() {
    if (!this.loading) {
      const {
        author_name,
        description,
        time_created,
        author_id,
      } = this.commentInfo;
      const { UserStore } = this.props;
      var comment = (
        <div>
          <p className="mt-2">{description}</p>
          <small>{time_created}</small>
          <br />
          <small>{author_name}</small>
          <br />

          {(UserStore.user.uid === author_id ||
            UserStore.user.admin ||
            UserStore.user.deputy) && (
            <button
              onClick={this.handleCommentDelete}
              className="btn btn-danger mt-2"
            >
              delete comment
            </button>
          )}
        </div>
      );
    }

    return <Main>{!this.loading ? comment : <Loader />}</Main>;
  }
}

export default Comment;

// STYLED COMPONENTS
const Main = styled.div`
  border: 1px solid #aaa;
  border-radius: 5px;
  max-width: 500px;
  margin: 10px auto;
  padding: 20px;
`;
