import { action, observable } from 'mobx';
import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';
import { NotificationManager } from 'react-notifications';

class UserStore {
  @observable isLogged = false;
  @observable userName = '';
  @observable userId = '';
  @observable user = {};
  @observable isAdmin = false;

  createNotification = (type, message, title) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info(message);
          break;
        case 'success':
          NotificationManager.success(message, title);
          break;
        case 'warning':
          NotificationManager.warning(
            'Warning message',
            'Close after 3000ms',
            3000
          );
          break;
        case 'error':
          NotificationManager.error(message, title, 5000, () => {
            alert('callback');
          });
          break;
        default:
          break;
      }
    };
  };

  @action logUserIn = (email, password, props) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        this.isLogged = true;
        this.userId = user.user.uid;
        firebase
          .database()
          .ref('/users/' + user.user.uid)
          .once('value')
          .then(snapshot => {
            const user = snapshot.val();
            this.user = user;
            localStorage.setItem('userName', user.name);
            localStorage.setItem('userEmail', user.email);
            localStorage.setItem('userId', user.uid);
            localStorage.setItem('isLogged', true);
            this.createNotification('success', 'welcome back!')();
            props.history.push('/');
          });
      })
      .catch(error => {
        var errorMessage = error.message;
        this.createNotification('error', errorMessage)();
      });
  };

  @action signupUser = (email, password, name, imgUrl, props) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        const newUser = {
          name,
          email,
          img_url: imgUrl,
          uid: user.user.uid,
        };
        this.createNotification('success', 'account created')();
        user.user
          .sendEmailVerification()
          .then(() => {
            this.createNotification(
              'info',
              'we sent you a verification email'
            )();
          })
          .catch(error => {
            this.createNotification('error', error)();
          });

        const database = firebase.database();
        let updates = {};
        updates['/users/' + user.user.uid] = newUser;
        this.user = newUser;
        localStorage.setItem('userName', newUser.name);
        localStorage.setItem('userEmail', newUser.email);
        localStorage.setItem('userId', newUser.uid);
        localStorage.setItem('isLogged', true);
        props.history.push('/');
        return database.ref().update(updates);
      })

      .catch(error => {
        var errorMessage = error.message;
        this.createNotification('error', errorMessage)();
      });
  };

  @action getCurrentUser = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.isLogged = true;
        localStorage.setItem('isLogged', true);

        firebase
          .database()
          .ref('/users/' + user.uid)
          .once('value')
          .then(snapshot => {
            const user = snapshot.val();
            this.user = user;
            localStorage.setItem('userName', user.name);
            localStorage.setItem('userEmail', user.email);
            localStorage.setItem('userId', user.uid);
            localStorage.setItem('isLogged', true);
          });
      } else {
        this.isLogged = false;
        localStorage.removeItem('userName');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userId');
        localStorage.removeItem('isLogged');
      }
    });
  };

  @action signUserOut = props => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem('userName');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userId');
        localStorage.removeItem('isLogged');
        this.isLogged = false;
        this.user = {};
        this.createNotification('success', 'logged out')();
      })
      .catch(error => {
        this.createNotification('error', error)();
      });
  };
}

export default new UserStore();
