import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';

import Loader from '../components/Loader';

@inject('notificationStore', 'dilemmaStore', 'UserStore')
@observer
class CreateCases extends Component {
  @observable action = '';
  @observable actions = ['action a', 'action b'];
  @observable description = '';
  @observable features = ['feature 1', 'feature 2', 'feature 3'];
  @observable featuresSelectedA = {};
  @observable featuresSelectedB = {};
  @observable loadingFeatures = true;
  @observable loadingActions = true;
  @observable title = '';

  componentDidMount() {
    const { dilemma_id } = this.props.match.params;

    if (!this.props.UserStore.isLogged) {
      this.props.history.push('/login');
    }

    firebase
      .database()
      .ref('/dilemmas/' + dilemma_id + '/actions')
      .once('value')
      .then(snapshot => {
        this.actions = snapshot.val();
        this.loadingActions = false;
      });

    const featuresObj = {};

    firebase
      .database()
      .ref('/dilemmas/' + dilemma_id + '/features')
      .once('value')
      .then(snapshot => {
        this.features = snapshot.val();
        snapshot.val().map(el => (featuresObj[el] = [false, '0']));
      })
      .then(() => {
        this.featuresSelectedA = Object.assign({}, featuresObj);
        this.featuresSelectedB = Object.assign({}, featuresObj);
        this.loadingFeatures = false;
      });
  }

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value;
  };

  @action handleRangeChange = e => {
    this.featuresSelectedA[e.target.name][1] = e.target.value;
  };

  @action handleInputFeatureChange = e => {
    this.featuresSelectedA[e.target.name][0] = e.target.checked;
    this.featuresSelectedB[e.target.name][0] = e.target.checked;
  };

  @action handleRangeChangeB = e => {
    this.featuresSelectedB[e.target.name][1] = e.target.value;
  };

  @action handleInputFeatureChangeB = e => {
    this.featuresSelectedB[e.target.name][0] = e.target.checked;
  };

  @action handleSubmit = e => {
    e.preventDefault();
    const database = firebase.database();
    const { dilemma_id } = this.props.match.params;

    let newCaseKey = database
      .ref()
      .child('dilemmas/' + dilemma_id + '/cases')
      .push().key;
    let updates = {};

    const author_id = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');

    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    }

    const newCase = {
      author_id: author_id ? author_id : 'anonymous',
      author_name: userName ? userName : 'anonymous',
      caseId: newCaseKey,
      title: this.title,
      description: this.description,
      correctAction: this.action,
      created: formatDate(new Date()),
      features_a: this.featuresSelectedA,
      features_b: this.featuresSelectedB,
    };

    updates['/dilemmas/' + dilemma_id + '/cases/' + newCaseKey] = newCase;
    return database.ref().update(updates, error => {
      if (error) {
        this.props.notificationStore.createNotification('error', error)();
      } else {
        this.props.notificationStore.createNotification(
          'success',
          'Case created'
        )();
        this.props.dilemmaStore.dilemmaValue = 2;
      }
    });
  };

  handleKeyPress = e => {
    if (e.charCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  @action removeFeature = (e, feature) => {
    if (this.features.length > 1) {
      this.features = this.features.filter(el => el !== feature);
    }
    if (this.features.length === 1) this.hidden = true;
  };

  render() {
    const [actionA, actionB] = this.actions;
    return (
      <div>
        <h5 className="text-center mb-4">create new case</h5>

        <form onSubmit={this.handleSubmit} onKeyPress={this.handleKeyPress}>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              onChange={this.handleInputChange}
              placeholder="case title"
              value={this.title}
              name="title"
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control"
              id="description"
              rows="3"
              onChange={this.handleInputChange}
              value={this.description}
              name="description"
              placeholder="case description"
              required
            />
          </div>
          <p className="mb-3">Correct action:</p>
          {this.loadingActions ? (
            <Loader />
          ) : (
            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={this.handleInputChange}
                  placeholder="action-a"
                  value={this.actions[0]}
                  name="action"
                  required
                />
                <label className="form-check-label" htmlFor="action">
                  {actionA}
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={this.handleInputChange}
                  placeholder="action-b"
                  value={this.actions[1]}
                  name="action"
                  required
                />
                <label className="form-check-label" htmlFor="action">
                  {actionB}
                </label>
              </div>
            </div>
          )}
          <hr />
          <p className="mb-3">Features:</p>
          {this.loadingFeatures ? (
            <Loader />
          ) : (
            <div className="row justify-content-center">
              <div className="col-sm-6">
                <p>{actionA}</p>
                {this.features.map(feature => (
                  <div key={feature}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={feature}
                        value={feature}
                        onChange={this.handleInputFeatureChange}
                        checked={this.featuresSelectedA[feature][0]}
                      />
                      <label className="form-check-label">{feature}</label>
                    </div>

                    <div className="form-group">
                      <input
                        className="form-control-range"
                        required
                        type="range"
                        name={feature}
                        min="-3"
                        max="3"
                        step="1"
                        value={this.featuresSelectedA[feature][1]}
                        disabled={!this.featuresSelectedA[feature][0]}
                        onChange={this.handleRangeChange}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm-6">
                <p>{actionB}</p>
                {this.features.map(feature => (
                  <div key={feature}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={feature}
                        value={feature}
                        onChange={this.handleInputFeatureChange}
                        checked={this.featuresSelectedA[feature][0]}
                      />
                      <label className="form-check-label">{feature}</label>
                    </div>

                    <div className="form-group">
                      <input
                        className="form-control-range"
                        type="range"
                        name={feature}
                        min="-3"
                        max="3"
                        step="1"
                        value={this.featuresSelectedB[feature][1]}
                        disabled={!this.featuresSelectedA[feature][0]}
                        onChange={this.handleRangeChangeB}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <br />
          <br />
          <button className="btn btn-success">submit</button>
        </form>
      </div>
    );
  }
}

export default withRouter(CreateCases);
