import React from 'react';
import styled from 'styled-components';

const DilemmaDescription = ({ description, actions, features, day }) => {
  return (
    <Main className="row">
      <div className="col-12 row">
        <p className="col-md-3">description</p>
        <p className="col-md-9">{description}</p>
      </div>
      <hr className="w-100" />
      <div className="mt-4 col-12 row">
        <p className="col-md-3">corect actions</p>
        <p className="col-md-9">
          {actions[0]} <span className="ml-4">{actions[1]}</span>
        </p>
      </div>
      <hr className="w-100" />
      <div className="mt-4 col-12 row">
        <p className="col-md-3">features</p>
        <StyledFeatures className="col-md-9">
          {features.map(element => (
            <span key={element} className="mr-4">
              {element}
            </span>
          ))}
        </StyledFeatures>
      </div>
      <hr className="w-100" />
      <div className="mt-4 col-12">
        <div className="row">
          <p className="col-md-3">Key takeaway/Duties</p>
          <StyledFeatures className="col-md-9">
            {features.map(element => (
              <div key={element}>
                <p>
                  It can be inferred that there are (prima facie) duties to:
                  {` maximize ${element}`}
                </p>
              </div>
            ))}
          </StyledFeatures>
        </div>
      </div>
      <hr className="w-100" />
      <div className="mt-4 col-12 row">
        <p className="col-md-3">Rules</p>
        <div className="col-md-9">
          {features.map(element => (
            <div key={element}>
              <p>
                An action is ethically preferable to another if it
                {` maximizes ${element} `} by at least 1
              </p>
            </div>
          ))}
        </div>
      </div>
    </Main>
  );
};

export default DilemmaDescription;

const Main = styled.div`
  font-size: 14px;
`;

const StyledFeatures = styled.div`
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;
