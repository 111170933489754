import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import dilemmaStore from '../stores/dilemmaStore';

/*
Automotive & Autonomous
Medical & Pharma
Caring & Nursing
Customer Relations
Social Interactions
Business, Trade, & Contracts
Financial Markets & Insurance
Robotics & Embedded
Applied Ethics
Legal & Liberties
International Affairs
Military & Policing
*/

@inject('UserStore', 'notificationStore', 'dilemmaStore')
@observer
class CreateDilemma extends Component {
  @observable description = '';
  @observable onEdit = false;
  @observable dilemmaId = '';
  @observable title = '';
  @observable actions = ['', ''];
  @observable features = [''];
  @observable autoFocus = false;
  @observable hidden = false;
  @observable imageSelected =
    'https://firebasestorage.googleapis.com/v0/b/open-eth.appspot.com/o/images%2Fvehicles-open-eth.jpg?alt=media&token=fd5c1ac6-af3b-4f26-b303-f9a8824c4012';

  @observable userName = '';
  @observable author_id = '';
  @observable dilemmaId = '';
  @observable time_created = '';
  @observable cases = {};
  @observable comments = {};
  @observable category = '';

  componentDidMount() {
    if (this.props.location.state) {
      const { dilemmaId } = this.props.location.state;
      this.onEdit = true;
      this.dilemmaId = dilemmaId;
      firebase
        .database()
        .ref('/dilemmas/' + dilemmaId)
        .once('value')
        .then((snapshot) => {
          const dilemma = snapshot.val();
          this.description = dilemma.description;
          this.title = dilemma.title;
          this.actions = dilemma.actions;
          this.features = dilemma.features;
          this.imageSelected = dilemma.img_url;
          this.userName = dilemma.userName;
          this.author_id = dilemma.author_id;
          this.dilemmaId = dilemma.dilemmaId;
          this.time_created = dilemma.time_created;
          this.cases = dilemma.cases ? dilemma.cases : {};
          this.comments = dilemma.comments ? dilemma.comments : {};
          this.category = dilemma.category;
        });
    }
  }

  @action handleInputChange = (e) => {
    console.log(this.category);
    console.log(e.target.name);
    console.log(e.target.value);

    if (e.target.name === 'actionA') {
      this.actions[0] = e.target.value;
    } else if (e.target.name === 'actionB') {
      this.actions[1] = e.target.value;
    } else {
      this[e.target.name] = e.target.value;
    }
  };

  @action handleInputFeatureChange = (index, e) => {
    this.features[index] = e.target.value;
  };

  @action handleSubmit = (e) => {
    e.preventDefault();
    const database = firebase.database();

    function formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    }

    let newDilemmaKey;
    let newDilemma;
    if (this.onEdit) {
      newDilemmaKey = this.dilemmaId;

      newDilemma = {
        img_url: dilemmaStore.getImgUrl(parseInt(this.category)),
        title: this.title,
        description: this.description,
        actions: this.actions,
        features: this.features,
        userName: this.userName,
        author_id: this.author_id,
        dilemmaId: this.dilemmaId,
        time_created: this.time_created,
        cases: this.cases,
        comments: this.comments,
        category: this.category,
      };
    } else {
      newDilemmaKey = database.ref().child('dilemmas').push().key;

      newDilemma = {
        userName: localStorage.getItem('userName')
          ? localStorage.getItem('userName')
          : 'anonymous',
        author_id: localStorage.getItem('userId')
          ? localStorage.getItem('userId')
          : 'anonymous',
        dilemmaId: newDilemmaKey,
        time_created: formatDate(new Date()),
        cases: {},
        comments: {},
        img_url: dilemmaStore.getImgUrl(parseInt(this.category)),
        title: this.title,
        description: this.description,
        actions: this.actions,
        features: this.features,
        category: this.category,
      };
    }

    let updates = {};
    updates['/dilemmas/' + newDilemmaKey] = newDilemma;
    return database.ref().update(updates, (error) => {
      if (error) {
        this.props.notificationStore.createNotification(
          'error',
          'there was an error'
        )();
      } else {
        this.props.notificationStore.createNotification(
          'success',
          'new dilemma created!',
          'dilemma created!'
        )();
        this.props.history.push('/');
      }
    });
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  @action removeFeature = (e, feature) => {
    if (this.features.length > 1) {
      this.features = this.features.filter((el) => el !== feature);
    }
    if (this.features.length === 1) this.hidden = true;
  };

  @action featureKeyPress = (e, index) => {
    if (e.charCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      if (this.features[this.features.length - 1] === '') {
        this.props.notificationStore.createNotification(
          'error',
          'cannot add empty field'
        )();
      } else if (this.features.length - 1 === index) {
        this.features.push('');
        this.autoFocus = true;
        this.hidden = false;
      }
    }
  };

  @action getUser = () => {
    const { getCurrentUser } = this.props.UserStore;
    getCurrentUser();
  };

  render() {
    return (
      <Main>
        <h3 className="mb-4 text-center">
          {this.onEdit ? 'Edit dilemma' : 'Create dilemma'}
        </h3>
        <form onSubmit={this.handleSubmit} onKeyPress={this.handleKeyPress}>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              onChange={this.handleInputChange}
              placeholder="dilemma title"
              value={this.title}
              name="title"
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control"
              id="description"
              rows="3"
              onChange={this.handleInputChange}
              value={this.description}
              name="description"
              placeholder="dilemma description"
              required
            />
          </div>
          <div className="mb-3">Correct answers: </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="actionA">Action A:</label>
              <input
                type="text"
                onChange={this.handleInputChange}
                placeholder="action a"
                value={this.actions[0]}
                name="actionA"
                required
                className="form-control"
              />
            </div>
            <div className="form-group col">
              <label htmlFor="actionB">Action B:</label>
              <input
                type="text"
                onChange={this.handleInputChange}
                placeholder="action b"
                value={this.actions[1]}
                name="actionB"
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="features">Features</label>
            {this.features.map((feature, index) => (
              <div key={index} className="input-group mb-2">
                <input
                  autoFocus={this.autoFocus}
                  onChange={(e) => this.handleInputFeatureChange(index, e)}
                  onKeyPress={(e) => this.featureKeyPress(e, index)}
                  type="text"
                  placeholder="add a feature"
                  value={feature}
                  name="feature"
                  required
                  className="form-control mb-1"
                />
                <div
                  className="input-group-prepend"
                  hidden={this.hidden}
                  onClick={(e) => this.removeFeature(e, feature)}>
                  <div style={{ height: '38px' }} className="input-group-text">
                    X
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              onChange={this.handleInputChange}
              id="category"
              name="category"
              className="form-control"
              required>
              <option value="">Choose...</option>
              <option value="1">Automotive & Autonomous</option>
              <option value="2">Medical & Pharma</option>
              <option value="3">Caring & Nursing</option>
              <option value="4">Customer Relations</option>
              <option value="5">Social Interactions</option>
              <option value="6">Business, Trade, & Contracts</option>
              <option value="7">Financial Markets & Insurance</option>
              <option value="8">Robotics & Embedded</option>
              <option value="9">Applied Ethics</option>
              <option value="10">Legal & Liberties</option>
              <option value="11">International Affairs</option>
              <option value="12">Military & Policing</option>
            </select>
          </div>

          <button className="btn btn-success mb-5">
            {this.onEdit ? 'Edit dilemma' : 'Create dilemma'}
          </button>
        </form>
      </Main>
    );
  }
}

export default CreateDilemma;

const Main = styled.div`
  margin: 50px auto;
  max-width: 700px;
`;

// const StyledImg = styled.img`
//   border-radius: 5px;
//   height: 100px;
//   margin: 20px 10px;
//   width: 100px;

//   :hover {
//     cursor: pointer;
//     transform: scale(1.1);
//     transition-duration: 0.5s;
//   }
// `;
