import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';

import SubMenu from '../components/SubMenu';
import Comments from '../comment/Comments';
import Comment from '../comment/Comment';
import CreateComment from '../comment/CreateComment';
import Cases from '../case/Cases';
import Case from '../case/Case';
import CreateCase from '../case/CreateCase';
import Loader from '../components/Loader';

import DilemmaDescription from './DilemmaDescription';

@inject('UserStore', 'dilemmaStore')
@observer
class Dilemma extends Component {
  @observable dilemma = null;
  @observable value = 2;

  componentDidMount() {
    this.props.UserStore.getCurrentUser();
    this.props.dilemmaStore.dilemmaValue = 1;
    const { dilemma_id } = this.props.match.params;

    let dilemma = firebase.database().ref('/dilemmas/' + dilemma_id);

    dilemma.on('value', snapshot => {
      if (!snapshot.val()) {
        this.props.history.push('/not_found');
      }
      this.dilemma = snapshot.val();
    });
  }

  render() {
    if (this.dilemma) {
      const {
        title,
        description,
        time_created,
        actions,
        features,
        cases,
        comments,
        img_url,
        author_id,
        userName,
        dilemmaId,
      } = this.dilemma;

      var date = new Date(time_created * 1000);
      var day = `${date.getDay()} - ${date.getMonth()} - ${date.getFullYear()}`;

      // var isnt block scoped
      var dilemma = (
        <div>
          <SubMenu
            title={title}
            img_url={img_url}
            userName={userName}
            author_id={author_id}
            dilemmaId={dilemmaId}
          />

          {this.props.dilemmaStore.dilemmaValue === 1 ? (
            <DilemmaDescription
              description={description}
              actions={actions}
              features={features}
              day={day}
            />
          ) : this.props.dilemmaStore.dilemmaValue === 2 ? (
            <Cases cases={cases} />
          ) : this.props.dilemmaStore.dilemmaValue === 3 ? (
            <Comments comments={comments} />
          ) : this.props.dilemmaStore.dilemmaValue === 4 ? (
            <CreateComment />
          ) : this.props.dilemmaStore.dilemmaValue === 5 ? (
            <CreateCase />
          ) : this.props.dilemmaStore.dilemmaValue === 6 ? (
            <Case cases={cases} actions={actions} />
          ) : this.props.dilemmaStore.dilemmaValue === 7 ? (
            <Comment />
          ) : (
            <p>something happened...</p>
          )}
        </div>
      );
    }

    return (
      <Main>
        {this.dilemma ? (
          dilemma
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </Main>
    );
  }
}

export default Dilemma;

const Main = styled.div`
  margin: 10px auto;
  max-width: 880px;
`;

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
`;
