import { NotificationManager } from 'react-notifications';

class notificationStore {
  createNotification = (type, message, title) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info(message);
          break;
        case 'success':
          NotificationManager.success(message, title);
          break;
        case 'warning':
          NotificationManager.warning(
            'Warning message',
            'Close after 3000ms',
            3000
          );
          break;
        case 'error':
          NotificationManager.error(message, title, 5000, () => {
            alert('callback');
          });
          break;
        default:
          break;
      }
    };
  };
}

export default new notificationStore();
