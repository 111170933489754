import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';

import { StyledLink } from '../components/StyledComponents';
import Loader from '../components/Loader';
import SearchIcon from '../images/search.png';

@inject('notificationStore')
@observer
class Dilemmas extends Component {
  @observable dilemmas = [];
  @observable search = '';
  @observable category = '';
  dilemmasImu = [];

  componentDidMount() {
    firebase
      .database()
      .ref('/dilemmas')
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const dilemmas = Object.entries(snapshot.val());
          this.dilemmas = dilemmas.map(dilemma => {
            const [id, el] = dilemma;
            return {
              id: id,
              title: el.title,
              category: el.category,
              description: el.description,
              imgUrl: el.img_url,
              created: el.time_created.substring(0, 10),
            };
          });
          this.dilemmasImu = [...this.dilemmas];
        } else {
          console.log(
            snapshot.val(),
            'there are no dilemmas on the database...'
          );
        }
      })
      .catch(error => console.log('there was an error fetching the data'));
  }

  @action handleSearchInput = ({ target }) => {
    this.search = target.value;
  };

  @action handleSearch = ({ charCode, target }) => {
    if (charCode === 13) {
      this.dilemmas = this.dilemmasImu.filter(el =>
        el.title.toLowerCase().includes(target.value.toLowerCase())
      );
      if (!this.dilemmas.length) {
        this.props.notificationStore.createNotification(
          'error',
          'No dilemmas found for ',
          target.value
        )();
      }
    }
  };

  @action handleSearchButton = () => {
    this.dilemmas = this.dilemmasImu.filter(el =>
      el.title.toLowerCase().includes(this.search.toLowerCase())
    );
    if (!this.dilemmas.length) {
      this.props.notificationStore.createNotification(
        'error',
        'No dilemmas found for ',
        this.search
      )();
    }
  };

  @action handleSelectChange = e => {
    if (e.target.value === '0') {
      this.dilemmas = this.dilemmasImu;
    } else {
      this.dilemmas = this.dilemmasImu.filter(
        el => el.category === e.target.value
      );
    }
  };

  render() {
    const dilemmas = this.dilemmas.map(el => {
      return (
        <Dilemma
          onClick={() => this.props.history.push('dilemma/' + el.id + '/')}
          key={el.id}
        >
          <h5>{el.title}</h5>
          <img style={{ height: '100px' }} src={el.imgUrl} alt="dilemma" />
          <span>{el.created}</span>
          <br />
          <p>{el.description}</p>
          <br />
          <button>OPEN</button>
        </Dilemma>
      );
    });

    return (
      <Main>
        <Description>
          Play with ethics exploring and adding dilemmas.
          <br />
          Help to list and specify more and less preferable ethical scenarios.
        </Description>
        <hr />
        <SubNav className="d-flex justify-content-between">
          <StyledLink className="mb-3" to="/dilemma/new">
            + add dilemma
          </StyledLink>
          <div className="mb-3 d-flex">
            <div
              className="input-group-prepend"
              onClick={this.handleSearchButton}
            >
              <span className="input-group-text" id="basic-addon1">
                <img src={SearchIcon} alt="search-icon" />
              </span>
            </div>
            <input
              onKeyPress={this.handleSearch}
              type="text"
              placeholder="search"
              aria-label="search"
              aria-describedby="basic-addon1"
              onChange={this.handleSearchInput}
              value={this.search}
            />
          </div>
        </SubNav>
        <div className="form-group" style={{ maxWidth: '400px' }}>
          <select
            onChange={this.handleSelectChange}
            id="category"
            name="category"
            className="form-control"
            required
            defaultValue="0"
          >
            <option value="0">All Categories</option>
            <option value="1">Automotive & Autonomous</option>
            <option value="2">Medical & Pharma</option>
            <option value="3">Caring & Nursing</option>
            <option value="4">Customer Relations</option>
            <option value="5">Social Interactions</option>
            <option value="6">Business, Trade, & Contracts</option>
            <option value="7">Financial Markets & Insurance</option>
            <option value="8">Robotics & Embedded</option>
            <option value="9">Applied Ethics</option>
            <option value="10">Legal & Liberties</option>
            <option value="11">International Affairs</option>
            <option value="12">Military & Policing</option>
          </select>
        </div>
        <DilemmasWrapper>
          {dilemmas.length ? dilemmas : <Loader />}
        </DilemmasWrapper>
      </Main>
    );
  }
}

export default Dilemmas;

const Main = styled.div`
  margin: 10px auto;
`;

const SubNav = styled.div`
  padding: 30px 0;

  input {
    border: 1px solid #aaa;
    border-radius: 0 5px 5px 0;
    padding: 5px;
    width: 400px;
  }

  span {
    background-color: yellow;
  }

  img {
    height: 22px;
    :hover {
      cursor: pointer;
    }
  }

  @media (max-width: 500px) {
    align-items: center;
    flex-direction: column;

    div {
      align-items: center;
      div {
        display: block;
      }
    }

    input {
      text-align: center;
      width: 300px;
    }
  }
`;

const Description = styled.p`
  color: #666;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin: 40px 0;
  text-align: center;
`;

const DilemmasWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Dilemma = styled.div`
  align-items: center;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  flex-direction: column;
  padding: 20px;
  margin: 35px 10px;
  width: 349px;

  button {
    border: 2px solid #000;
    border-radius: 4px;
    background-color: transparent;
    display: block;
    font-size: 8px;
    font-weight: 900;
    padding: 10px 26px;
    margin-left: auto;
    margin-right: auto;
    transition: 0.1s background-color linear, 0.1s color linear;
    width: 87px;
  }

  h5 {
    font-size: 15px;
    text-transform: capitalize;
  }

  img {
    margin-bottom: 20px;
  }

  p {
    min-height: 85px;
  }

  :hover {
    border-radius: 3px;
    box-shadow: 1px 1px 13px -5px #aaa;
  }
`;
