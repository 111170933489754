import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  color: #999;
  font-size: 13px;
  margin-left: 30px;
  text-transform: uppercase;

  :hover {
    color: #222;
    cursor: pointer;
    text-decoration: none;
  }
`;
