import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import { withRouter } from 'react-router-dom';

import Loader from '../components/Loader';

@withRouter
@inject('UserStore', 'notificationStore', 'dilemmaStore')
@observer
class Case extends Component {
  @observable caseInfo = {};
  @observable loading = true;

  componentDidMount() {
    const { dilemma_id } = this.props.match.params;
    const case_id = this.props.dilemmaStore.caseId;

    firebase
      .database()
      .ref('/dilemmas/' + dilemma_id + '/cases/' + case_id)
      .once('value')
      .then(snapshot => {
        this.caseInfo = snapshot.val();
        this.loading = false;
      });
  }

  deleteCase = () => {
    const { UserStore, notificationStore, dilemmaStore } = this.props;
    const { author_id, caseId } = this.caseInfo;
    const { dilemma_id } = this.props.match.params;

    if (
      UserStore.user.uid === author_id ||
      UserStore.user.admin ||
      UserStore.user.deputy
    ) {
      const confirm = window.confirm('are you sure?');
      if (confirm) {
        let updates = {};
        updates['/dilemmas/' + dilemma_id + '/cases/' + caseId] = {};
        return firebase
          .database()
          .ref()
          .update(updates, error => {
            if (error) {
              notificationStore.createNotification('error', error)();
            } else {
              notificationStore.createNotification('success', 'case deleted')();
              dilemmaStore.dilemmaValue = 2;
            }
          });
      }
    }
  };

  render() {
    if (!this.loading) {
      const {
        title,
        description,
        correctAction,
        features_a,
        features_b,
        author_name,
        created,
        author_id,
      } = this.caseInfo;

      const [actionA, actionB] = this.props.actions;

      let fDetailsNew = [];
      if (features_a) {
        fDetailsNew = Object.entries(features_a).filter(el => el[1][0]);
      }

      let arr = [];

      if (features_b) {
        arr = Object.values(features_b).filter(el => el[0]);

        if (arr.length) {
          for (let i = 0; i < fDetailsNew.length; i++) {
            fDetailsNew[i].push(arr[i][1]);
          }
        }
      }

      // var isnt block scoped
      var caseInfo = (
        <div>
          <h5 className="text-center">{title}</h5>
          <h6 className="text-center">Added by {author_name}</h6>
          <h6 className="mb-3">{created}</h6>
          {(this.props.UserStore.user.uid === author_id ||
            this.props.UserStore.user.admin ||
            this.props.UserStore.user.deputy) && (
            <div className="mr-2">
              <button className="btn btn-danger" onClick={this.deleteCase}>
                delete
              </button>
            </div>
          )}
          <p className="mt-4">{description}</p>
          <hr />
          <h6 className="mt-4">Correct action:</h6>
          <p>{correctAction}</p>
          <hr />
          <div className="row mb-3">
            <div className="col-6">Features</div>
            <div className="col-3 text-right">{actionA}</div>
            <div className="col-3 text-right">{actionB}</div>
          </div>
          {fDetailsNew.map(el => (
            <div key={el[0]}>
              <div className="row">
                <div className="col-6">{el[0]}</div>
                <div className="col-3 text-right">{el[1][1]}</div>
                <div className="col-3 text-right">{el[2]}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <Main>
        {!this.loading ? (
          caseInfo
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </Main>
    );
  }
}

export default Case;

const Main = styled.div`
  margin-bottom: 40px;
`;

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 400px;
`;
