import React from 'react';
import styled from 'styled-components';

const NotFound = () => (
  <Main>
    404 <br /> NOT FOUND <br />
    <small>this page you're looking for does not exist</small>
  </Main>
);

export default NotFound;

const Main = styled.div`
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 30px;
  justify-content: center;
  min-height: 60vh;

  small {
    font-size: 13px;
  }
`;
