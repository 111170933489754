import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app';
import 'firebase/database';

import arrow from '../images/arrow.jpg';

function SubMenu({
  UserStore,
  notificationStore,
  dilemmaStore,
  title,
  img_url,
  userName,
  match,
  author_id,
  history,
  dilemmaId,
}) {
  const deleteDilema = () => {
    const { dilemma_id } = match.params;
    if (window.confirm('are you sure you want to delete this dilemma?')) {
      if (
        UserStore.user.uid === author_id ||
        UserStore.user.admin ||
        UserStore.user.deputy
      ) {
        let updates = {};
        updates['/dilemmas/' + dilemma_id] = {};
        return firebase
          .database()
          .ref()
          .update(updates, (error) => {
            if (error) {
              notificationStore.createNotification('error', error)();
            } else {
              notificationStore.createNotification(
                'success',
                'dilemma deleted'
              )();
              history.push('/');
            }
          });
      }
    }
  };

  return (
    <>
      <SubHeader className="row">
        <SyledLink className="col-md-3 main-color" to="/">
          <img src={arrow} alt="" /> List of dilemmas
        </SyledLink>
        <DilemmaHeader className="col-md-9 d-flex align-items-center">
          <Img className="mr-5" src={img_url} />
          <div>
            <h5 className="mt-4">{title}</h5>
            <p>{`Added by ${userName}`}</p>
          </div>
          {(UserStore.user.uid === author_id ||
            UserStore.user.admin ||
            UserStore.user.deputy) && (
            <div className="mr-2">
              <button className="btn btn-danger ml-2" onClick={deleteDilema}>
                delete
              </button>
              <button
                className="btn btn-warning ml-2"
                onClick={() =>
                  history.push({
                    pathname: '/dilemma/new',
                    search: '',
                    state: { dilemmaId },
                  })
                }>
                edit
              </button>
            </div>
          )}
        </DilemmaHeader>
      </SubHeader>
      <hr />
      <DilemmaMenu className="d-flex justify-content-center">
        <Button
          onClick={() => dilemmaStore.setDilemmaValue(1)}
          className="btn btn-outline-secondary">
          DILEMMA DESCRIPTION
        </Button>
        <Button
          onClick={() => dilemmaStore.setDilemmaValue(2)}
          className="btn btn-outline-secondary">
          CASES
        </Button>
        <Button
          onClick={() => dilemmaStore.setDilemmaValue(3)}
          className="btn btn-outline-secondary">
          DISCUSSION
        </Button>
      </DilemmaMenu>
      <hr />
    </>
  );
}

export default withRouter(
  inject('UserStore', 'notificationStore', 'dilemmaStore')(observer(SubMenu))
);

const SubHeader = styled.div`
  align-items: center;
  display: flex;
  min-height: 80px;
  p {
    font-size: 13px;
  }
`;

const DilemmaHeader = styled.div`
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Img = styled.img`
  width: 80px;
  @media (max-width: 500px) {
    margin-left: 3rem;
    margin-top: 20px;
  }
`;

const SyledLink = styled(Link)`
  font-size: 12px;
  :hover {
    color: #222;
  }
`;

const DilemmaMenu = styled.div`
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  margin: 5px 20px;
  @media (max-width: 500px) {
    margin: 5px 0;
  }
`;
