import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';

@inject('notificationStore')
@observer
class ResetPassword extends Component {
  @observable email = '';

  @action handleInputChange = e => {
    this[e.target.name] = e.target.value;
  };

  @action handleSubmit = e => {
    e.preventDefault();
    firebase
      .auth()
      .sendPasswordResetEmail(this.email)
      .then(() => {
        this.props.notificationStore.createNotification(
          'success',
          'we sent you an email with the instructions',
          'email sent'
        )();
        this.props.history.push('/email_sent');
      })
      .catch(error => {
        if (
          error.message ===
          'There is no user record corresponding to this identifier. The user may have been deleted.'
        ) {
          this.props.notificationStore.createNotification(
            'error',
            "Email address doesn't exist on our records"
          )();
        } else {
          this.props.notificationStore.createNotification(
            'error',
            error.message
          )();
        }
      });
  };

  render() {
    return (
      <div style={{ maxWidth: '500px', margin: '50px auto' }}>
        <h5 className="text-center text-primary mb-3">Reset your password</h5>
        <p>
          we'll send you an email with the next steps to reset your password
        </p>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              value={this.email}
              onChange={this.handleInputChange}
              name="email"
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <button className="btn btn-info">Reset password</button>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
