import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

function Comments(props) {
  if (!props.comments) {
    return (
      <Main className="d-flex align-items-center justify-content-center flex-column">
        <div className="text-center mt-3">
          there are not comments for this dilemma yet...
        </div>
        <button
          onClick={() => props.dilemmaStore.setDilemmaValue(4)}
          className="btn btn-success mt-3 w-50"
        >
          add comment
        </button>
      </Main>
    );
  }

  const comments = Object.entries(props.comments);
  const commentsArr = comments.map(el => {
    const [key, value] = el;
    return {
      key,
      value,
    };
  });

  const commentsEl = commentsArr.map(el => (
    <CommentsStyled
      className="col-md-9"
      key={el.key}
      onClick={() => props.dilemmaStore.setDilemmaValue(7, 'comment', el.key)}
    >
      <small>{el.value.author_name}</small>
      <br />
      <small>{el.value.time_created}</small>
      <p className="mt-2">{el.value.description}</p>
    </CommentsStyled>
  ));

  return (
    <Main className="row">
      <div className="col-md-3">
        <p className="ml-2">COMENTS</p>

        <button
          className="btn btn-info"
          onClick={() => props.dilemmaStore.setDilemmaValue(4)}
        >
          add comment
        </button>
      </div>
      <div className="col-md-9">
        {comments.length ? commentsEl : <p>loading...</p>}
      </div>
    </Main>
  );
}

export default withRouter(inject('dilemmaStore')(observer(Comments)));

// STYLED COMPONENTS
const Main = styled.div`
  font-size: 14px;
`;

const CommentsStyled = styled.div`
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 50px;

  :hover {
    box-shadow: 1px 1px 5px #999;
    cursor: pointer;
  }

  span {
    font-size: 12px;
  }
`;
